
#filter{
   margin-left: 175px;
   padding: 10px;
}

#filter-1{
    margin-left: 100px;
    padding: 10px;
 }

.select {
    margin: 5px;
    width: 100px;
    height: 20px;
}

.tooltip {	
    position: absolute;			
    text-align: center;			
    width: 120px;					
    height: 55px;					
    padding: 2px;				
    background: lightsteelblue;	
    border: 0px;		
    border-radius: 8px;	
    pointer-events: none;		
}

.tooltip-2 {	
    position: absolute;			
    text-align: center;			
    width: 140px;					
    height: 80px;					
    padding: 2px;				
    background: lightsteelblue;	
    border: 0px;		
    border-radius: 8px;	
    pointer-events: none;		
}
/* Include any custom CSS styling here! */
