
:root {
    --lightest: #f8f8f8;
    --darkest: #1d3557;
    --tertiary: #f8f8f8;
}

.App{
    font-family: 'gotham rounded', 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App .header{
    padding: 2.5rem;
    font-weight: bold;
    color: var(--darkest);
}

html, body{
    height: 100%
}

.head_icon{
    color: #e09f3e;
}

.projects-header{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.footer{
    text-align: center;
    margin-top: 2rem;
    background-color: #f4f4f4;
}

.footer h3{
    font-weight: bold;
    display: inline-block;
    color: var(--darkest);
    padding: 0.5rem;
}

.scroll{
    color: var(--darkest);
}

.projects-header h2{
    font-weight: bold;
    display: inline-block;
    color: var(--darkest);
    margin-top: 1rem;
}

.projects-contents{
    padding-bottom: 1rem;
}

.blog{
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #f4f4f4;
    text-align: center;
}

.blog p{
    font-size: 1.25rem;
    font-weight: 400;
    padding-bottom: 4rem;
}

.blog-header{
    padding-top: 4rem;
    padding-bottom: 2rem;
}
.blog-header h2, h1{
    font-weight: bold;
    display: inline-block;
    color: var(--darkest);
}

.hi{
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.hi p{
    font-size: 1.25rem;
    font-weight: 400;
    padding-bottom: 4rem;
}

.hi-header{
    padding-top: 4rem;
    padding-bottom: 2rem;
}
.hi-header h2, h1{
    font-weight: bold;
    display: inline-block;
    color: var(--darkest);
}

.single-project-header{
    font-weight: bold;
    color: var(--darkest);
    margin-left: 2.5rem;
}

.single-project-container{
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 2rem;
}

.single-project-container h3, h4{
    font-weight: bold;
    color: var(--darkest);
    padding: 0.75rem;
}
.single-project-container p{
    padding: 0.75rem;
    font-size: 1.25rem;
}
.hidden-div{
    display: none;
}

.single-project-image{
    width: 60%;
    height: auto;
} 

.button-project {
    background-color: var(--darkest);
    color: var(--lightest);
    border: none;
    margin-left: 1rem;
}


.icon{
    padding: 0.5rem;
    color: var(--darkest);
}

.icon-phone{
    background-color: var(--darkest);
    color: var(--lightest);
    border: none;
    vertical-align: 0.5rem;
    margin-left: 0.25rem;
    font-weight: 400;
}

.about{
    padding: 2rem;
    text-align: center;
}

.cards{
    margin-bottom: 2rem;
}

.card-img{
    height: 188px;
}

.card{
    background-color: #f4f4f4;
    color: #1d3557;
    font-weight: 300;
    /* height: 366px;
    width: 349px; */
}

.cards a{
    text-decoration: none;
    color: #1d3557;
    font-weight: 300;

}

.projects{
    margin-bottom: 2rem;
}

.card Button{
    background-color: var(--darkest);
    color: #f4f4f4;
    border: none
}

.resume{
    background-color: var(--darkest);
    color: var(--lightest);
    border: none;
    vertical-align: 0.5rem;
    margin-left: 0.5rem;
    font-weight: 400;
}

.bio{
    display: block;
    padding: 0.5rem;
    font-weight: 400;
    font-size: 1.25em;
    text-align: left;
}

.bio p{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.bio h3{
    font-weight: bold;
    color: var(--darkest);
}
.profile{
    width: 70%;
    height: auto;
    padding: 0.5rem;
}

.rounded{
    border-radius: 0.8rem!important;
}



body{
    background-color: var(--lightest);
}

@media screen and (max-width: 600px){
    .bio{
        margin-top: 2rem;
        text-align: center;
    }

    .cards{
        margin-bottom: 0;
    }

    .single-project-image{
        width: 100%;
        height: auto;
    } 

    .card-img{
        height: auto;
    }


    
}

@media screen and (max-width: 800px) and (min-width: 650px){

    .card-img{
        display: none;
    }
   

}

@media screen and (max-width: 1200px){
    .data-viz{
        display: none;
    }

    .hidden-div{
        display: inline-block;
    }

}

@media screen and (max-width: 400px){

    /* .card{
        height: 320px;
        width: 284px;
    } */
    .resume{
        margin-top: 0.5rem;
    }

    /* .cards a{
        text-align: center;
    } */

} 
